<nav *ngIf="userData != null && userData.status != 4" style="position: fixed"
    class="navbar navbar-expand-lg navigation sticky navi-top-bar" (window:scroll)="windowScroll()" id="navbar"
    [dir]="textStyle">
    <div class="container col-lg-12">
        <a class="navbar-logo" (click)="logonavigate()">
            <!-- <img loading="lazy" src="assets/images/Logo.png" alt="" height="19" class="logo logo-dark"> -->
            <img style="cursor: pointer" loading="lazy" src="/assets/images/svg/rentalshare_logo.svg" alt=""
                class="logo" />
        </a>

        <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleSidebarnav()"
            style="cursor: pointer">
            <i class="bx bx-menu-alt-left font-size-32"></i>
        </button>

        <button *ngIf="userData !== null && userType ===! 'supplier'" type="button"
            class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleSidebar()"
            style="cursor: pointer">
            <i class="bx bx-menu-alt-left font-size-32"></i>
        </button>


        <div class="side-drawer" [class.open]="issupSidebarOpen">
            <div class="side-drawer-header">
                <button class="close-btn" (click)="toggleSidebarnav()">&#10005;</button>
            </div>



            <div class="ms-4 dropdown d-inline-block mt-2" ngbDropdown>
                <!-- item-->

                <div class=" pe-2 pt-1 d-flex" (click)="navigate('/profile')">
                    <div class="ms-2 mt-2" style="cursor: pointer;">
                        <ng-container *ngIf="!profilePic; else profileImgPlaceholder">
                            <span class="avatar-title header-profile-dropdown fs-4 bg-dayim">
                                {{ userData.fullName[0].toUpperCase() }}
                            </span>
                        </ng-container>
                        <ng-template #profileImgPlaceholder>
                            <img loading="lazy" [src]="profilePic" class="header-profile-dropdown mb-1"
                                alt="" />
                        </ng-template>
                    </div>
                    <div class="p-3" style="cursor: pointer;">
                        <div class="profile-user-label">
                            {{
                            userData.fullName.charAt(0).toUpperCase() +
                            userData.fullName.slice(1)
                            }}
                        </div>
                        <div>{{ userData.email }}</div>
                    </div>
                </div>
                <div class="line-profile"></div>
                <div style=" max-height: 400px; ">
                    <div class="dropdown-item" href="javascript: void(0);"
                        (click)="onLayoutChange('/my-accounts')" style="cursor: pointer">
                        {{ "HEADER.SETTINGS" | translate }}
                    </div>

                    <div class="dropdown-item" href="javascript: void(0);"
                        (click)="onLayoutChange('/my-rentals')" style="cursor: pointer">
                        {{ "HEADER.RENTALS" | translate }}
                    </div>

                    <div class="dropdown-item" href="javascript: void(0);"
                        (click)="onLayoutChange('/inquiries')" style="cursor: pointer">
                        {{ "HEADER.INQUIRIES" | translate }}
                    </div>

                    <div class="dropdown-item" href="javascript: void(0);"
                        (click)="onLayoutChange('/my-contracts')" style="cursor: pointer">
                        {{ "HEADER.CONTRACTS" | translate }}
                    </div>

                    <div class="dropdown-item" href="javascript: void(0);"
                        (click)="onLayoutChange('/my-deliveries')" style="cursor: pointer">
                        {{ "HEADER.DELIVERIES" | translate }}
                    </div>

                    <div class="dropdown-item" href="javascript: void(0);"
                        (click)="onLayoutChange('/my-listings')" style="cursor: pointer">
                        {{ "HEADER.LISTING" | translate }}
                    </div>
                    <div class="dropdown-item" href="javascript: void(0);"
                        (click)="onLayoutChange('/account-deleteing')" style="cursor: pointer">
                        {{ "HEADER.DELETE_YOUR_ACCOUNT" | translate }}
                    </div>

                    <div class="dropdown-item" href="javascript: void(0);" *ngFor="let item of lang">
                        <div *ngIf="item.lang === 'en'" class="d-flex justify-content-between">
                            <div class="ms-4 lang-bg-light" style="cursor: pointer"
                                (click)="setLanguage('en')">
                                {{ "HEADER.ENGLISH" | translate }}
                                <img loading="lazy" [src]="getLanguageFlag('en')" class="mb-1 mx-1"
                                    alt="" />
                            </div>
                            <- {{ "HEADER.LANGUAGE" | translate }} </div>
                                <div *ngIf="item.lang === 'ar'" class="d-flex justify-content-between">
                                    {{ "HEADER.LANGUAGE" | translate }}
                                    <div class="ms-4 lang-bg-light" style="cursor: pointer"
                                        (click)="setLanguage('ar')">
                                        العربية
                                        <img style="width: 16px" loading="lazy"
                                            [src]="getLanguageFlag('ar')" class="mb-1" alt="" />
                                    </div>
                                </div>
                        </div>

                        <div class="dropdown-logout-res pb-3" href="javascript: void(0);" (click)="logout()"
                            style="cursor: pointer">
                            {{ "HEADER.LOG_OUT" | translate }}
                        </div>
                    </div>
                </div>

            </div>

            <div class="side-drawer" [class.open]="isSidebarOpen">
                <div class="side-drawer-header">
                    <button class="close-btn" (click)="toggleSidebar()">&#10005;</button>
                </div>



                <div class="ms-4 dropdown d-inline-block mt-2" ngbDropdown>


                    <div class=" pt-1 d-flex" (click)="navigate('/profile')">
                        <div class="ms-2 mt-2" style="cursor: pointer;">
                            <ng-container *ngIf="!profilePic; else profileImgPlaceholder" style="cursor: pointer;">
                                <span class="avatar-title header-profile-dropdown fs-4 bg-dayim">
                                    {{ userData.fullName[0].toUpperCase() }}
                                </span>
                            </ng-container>
                            <ng-template #profileImgPlaceholder>
                                <img loading="lazy" [src]="profilePic" class="header-profile-dropdown mb-1" alt="" />
                            </ng-template>
                        </div>

                        <div class="p-3" style="cursor: pointer">
                            <div class="profile-user-label">
                                {{
                                userData.fullName.charAt(0).toUpperCase() +
                                userData.fullName.slice(1)
                                }}
                            </div>
                            <div>{{ userData.email }}</div>
                        </div>
                    </div>
                    <div class="line-profile"></div>
                    <div style=" max-height: 400px;">
                        <div class="dropdown-item" href="javascript: void(0);" (click)="onLayoutChange('/my-accounts')"
                            style="cursor: pointer">
                            {{ "HEADER.SETTINGS" | translate }}
                        </div>
                        <div class="dropdown-item" href="javascript: void(0);" (click)="onLayoutChange('/my-rentals')"
                            style="cursor: pointer">
                            {{ "HEADER.RENTALS" | translate }}
                        </div>
                        <div class="dropdown-item" href="javascript: void(0);" (click)="onLayoutChange('/favourites')"
                            style="cursor: pointer">
                            {{ "HEADER.FAVORITES" | translate }}
                        </div>
                        <div class="dropdown-item" href="javascript: void(0);" (click)="onLayoutChange('/inquiries')"
                            style="cursor: pointer">
                            {{ "HEADER.INQUIRIES" | translate }}
                        </div>

                        <div class="dropdown-item" href="javascript: void(0);" (click)="onLayoutChange('/my-contracts')"
                            style="cursor: pointer">
                            {{ "HEADER.CONTRACTS" | translate }}
                        </div>

                        <div class="dropdown-item" href="javascript: void(0);"
                            (click)="onLayoutChange('/my-deliveries')" style="cursor: pointer">
                            {{ "HEADER.DELIVERIES" | translate }}
                        </div>

                        <div class="dropdown-item" href="javascript: void(0);"
                            (click)="onLayoutChange('/account-deleteing')" style="cursor: pointer">
                            {{ "HEADER.DELETE_YOUR_ACCOUNT" | translate }}
                        </div>

                        <div class="dropdown-item" href="javascript: void(0);" *ngFor="let item of lang">
                            <div *ngIf="item.lang === 'en'" class="d-flex justify-content-between">
                                <div class="ms-4 lang-bg-light" style="cursor: pointer" (click)="setLanguage('en')">
                                    {{ "HEADER.ENGLISH" | translate }}
                                    <img loading="lazy" [src]="getLanguageFlag('en')" class="mb-1 mx-1" alt="" />
                                </div>
                                <- {{ "HEADER.LANGUAGE" | translate }} </div>
                                    <div *ngIf="item.lang === 'ar'" class="d-flex justify-content-between">
                                        {{ "HEADER.LANGUAGE" | translate }}
                                        <div class="ms-4 lang-bg-light" style="cursor: pointer"
                                            (click)="setLanguage('ar')">
                                            العربية
                                            <img style="width: 16px" loading="lazy" [src]="getLanguageFlag('ar')"
                                                class="mb-1" alt="" />
                                        </div>
                                    </div>
                            </div>

                            <div class="dropdown-logout-res pb-3" href="javascript: void(0);" (click)="logout()"
                                style="cursor: pointer">
                                {{ "HEADER.LOG_OUT" | translate }}
                            </div>
                        </div>

                        <!--     
                  <a class="dropdown-item" href="javascript: void(0);" (click)="onLayoutChange('/profile')"><i
                      class="bx bx-user-circle font-size-16 align-middle me-1"></i>
                    {{ 'MENUITEMS.PROFILE' | translate}}</a> -->

                        <!-- <a class="dropdown-item" href="javascript: void(0);" (click)="onLayoutChange('/cart')" ><i
                  class="bx bx-cart font-size-16 align-middle me-1"></i>
                {{ 'MENUITEMS.CART' | translate}}</a>
              <a class="dropdown-item" href="javascript: void(0);" (click)="onLayoutChange('/notifications')" ><i
                  class="bx bx-bell font-size-16 align-middle me-1"></i>
                {{ 'MENUITEMS.NOTIFICATIONS' | translate}}</a> -->
                        <!-- <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()">
                {{ 'MENUITEMS.LOGOUT' | translate}}</a> -->
                    </div>
                </div>

                <div class="collapse navbar-collapse div-nav-tab" id="topnav-menu-content">
                    <ul class="navbar-nav ms-auto m-4 me-2" id="topnav-menu">
                        <li class="nav-item" *ngIf="userData === null">
                            <div class="ms-auto m-4 me-2 rent-btn" id="topnav-menu" *ngIf="userData === null"
                                (click)="navigate('/add-listing')" style="cursor: pointer">
                                <div>{{ "HEADER.LIST_EQUIPMENT" | translate }}</div>
                            </div>
                        </li>
                        <ng-container *ngIf="userData === null">
                            <div class="divider mt-3 mx-2"></div>
                        </ng-container>
                    </ul>
                    <ul class="navbar-nav ms-auto m-4 me-2" id="topnav-menu"
                        *ngIf="userData !== null && userType === 'supplier'">
                        <li class="nav-item">
                            <div class="ms-auto m-4 me-2 rent-btn" id="topnav-menu" (click)="listNavigate()"
                                style="cursor: pointer">
                                <div>{{ "HEADER.LIST_EQUIPMENT" | translate }}</div>
                            </div>
                        </li>
                        <ng-container *ngIf="userData !== null && userType === 'supplier'">
                            <div class="divider mt-3 mx-2"></div>
                        </ng-container>
                    </ul>

                    <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData === null">
                        <div class="ms-auto m-4 me-2 free-btn" id="topnav-menu" *ngIf="userData === null"
                            (click)="navigate('/about-us')" style="cursor: pointer">
                            <div>{{ "HEADER.ABOUT_US" | translate }}</div>
                        </div>
                    </div>

                    <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData === null">
                        <div class="ms-auto m-4 me-2 free-btn" id="topnav-menu" *ngIf="userData === null"
                            (click)="navigate('/contact-us')" style="cursor: pointer">
                            <div>{{ "HEADER.CONTACT_US" | translate }}</div>
                        </div>
                    </div>

                    <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData === null">
                        <div class="ms-auto m-4 me-2 free-btn" id="topnav-menu" *ngIf="userData === null"
                            (click)="navigate('/account/login')" style="cursor: pointer">
                            <div>
                                {{ "HEADER.SIGN_IN" | translate }} /
                                {{ "HEADER.REGISTER" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData !== null">
                        <a class="ps-4" routerLink="/contact-us" style="cursor: pointer">
                            <img loading="lazy" src="/assets/images/svg/help.svg" alt="" height="10" width="10"
                                class="logo" />
                        </a>
                    </div>

                    <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData !== null">
                        <a class="ps-4 position-relative" routerLink="/notifications"
                            (click)="onNotificationClick($event)">
                            <img loading="lazy" src="/assets/images/svg/notify.svg" alt="" height="10" width="10"
                                class="logo" />
                            <span *ngIf="notificationCount > 0" class="notification-dot"></span>
                        </a>
                    </div>


                    <div
                        *ngIf=" userData !== null && userData.userType.typeName === 'customer'; then customerTemplate ">
                        <a class="ps-4" routerLink="/profile">
                            <img loading="lazy" [src]="profilePic" alt="" height="10" width="10" class="logo" />
                        </a>
                    </div>
                    <div
                        *ngIf=" userData !== null && userData.userType.typeName === 'supplier'; then supplierTemplate ">
                        <a class="ps-4" routerLink="/profile">
                            <img loading="lazy" [src]="profilePic" alt="" height="10" width="10" class="logo" />
                        </a>
                    </div>

                    <ng-template #customerTemplate>
                        <div class="ms-4 dropdown d-inline-block mt-2" ngbDropdown>
                            <button type="button" class="btn header-item d-inline-flex align-items-center ps-1"
                                ngbDropdownToggle id="page-header-user-dropdown">
                                <div *ngIf=" profilePic === undefined || profilePic === null || profilePic === ''; else profileImg "
                                    class="header-profile-user avatar-sm mb-1 bg-white">
                                    <span class="avatar-title rounded-circle fs-4 bg-dayim">
                                        {{ userData.fullName[0].toUpperCase() }}
                                    </span>
                                </div>
                                <ng-template #profileImg>
                                    <img loading="lazy" [src]="profilePic" class="header-profile-user mb-1" alt="" />

                                    <!-- <img loading="lazy" class="rounded-circle header-profile-user mb-1"  [src]="userData.profile" alt="{{userData.name}}"> -->
                                </ng-template>
                                <span class="d-none d-xl-inline-block mb-1">{{
                                    userData.name
                                    }}</span>
                                <!-- <i class="bx bx-chevron-down d-none d-xl-inline-block mb-1"></i> -->
                            </button>

                            <div class="dropdown-menu mt-2" ngbDropdownMenu
                                [ngClass]="{ 'dropdown-menu-right': cookieValue === 'en' }">
                                <!-- item-->

                                <div class="ps-3 pe-2 pt-1 d-flex" (click)="navigate('/profile')">
                                    <div class="ms-2 mt-2" style="cursor: pointer;">
                                        <ng-container *ngIf="!profilePic; else profileImgPlaceholder"
                                            style="cursor: pointer;">
                                            <span class="avatar-title header-profile-dropdown fs-4 bg-dayim">
                                                {{ userData.fullName[0].toUpperCase() }}
                                            </span>
                                        </ng-container>
                                        <ng-template #profileImgPlaceholder>
                                            <img loading="lazy" [src]="profilePic" class="header-profile-dropdown mb-1"
                                                alt="" />
                                        </ng-template>
                                    </div>

                                    <div class="p-3" style="cursor: pointer">
                                        <div class="profile-user-label">
                                            {{
                                            userData.fullName.charAt(0).toUpperCase() +
                                            userData.fullName.slice(1)
                                            }}
                                        </div>
                                        <div>{{ userData.email }}</div>
                                    </div>
                                </div>
                                <div class="line-profile"></div>
                                <div style=" max-height: 400px;">
                                    <div class="dropdown-item" href="javascript: void(0);"
                                        (click)="onLayoutChange('/my-accounts')" style="cursor: pointer">
                                        {{ "HEADER.SETTINGS" | translate }}
                                    </div>
                                    <div class="dropdown-item" href="javascript: void(0);"
                                        (click)="onLayoutChange('/my-rentals')" style="cursor: pointer">
                                        {{ "HEADER.RENTALS" | translate }}
                                    </div>
                                    <div class="dropdown-item" href="javascript: void(0);"
                                        (click)="onLayoutChange('/favourites')" style="cursor: pointer">
                                        {{ "HEADER.FAVORITES" | translate }}
                                    </div>
                                    <div class="dropdown-item" href="javascript: void(0);"
                                        (click)="onLayoutChange('/inquiries')" style="cursor: pointer">
                                        {{ "HEADER.INQUIRIES" | translate }}
                                    </div>

                                    <div class="dropdown-item" href="javascript: void(0);"
                                        (click)="onLayoutChange('/my-contracts')" style="cursor: pointer">
                                        {{ "HEADER.CONTRACTS" | translate }}
                                    </div>

                                    <div class="dropdown-item" href="javascript: void(0);"
                                        (click)="onLayoutChange('/my-deliveries')" style="cursor: pointer">
                                        {{ "HEADER.DELIVERIES" | translate }}
                                    </div>

                                    <div class="dropdown-item" href="javascript: void(0);"
                                        (click)="onLayoutChange('/account-deleteing')" style="cursor: pointer">
                                        {{ "HEADER.DELETE_YOUR_ACCOUNT" | translate }}
                                    </div>

                                    <div class="dropdown-item" href="javascript: void(0);" *ngFor="let item of lang">
                                        <div *ngIf="item.lang === 'en'" class="d-flex justify-content-between">
                                            <div class="ms-4 lang-bg-light" style="cursor: pointer"
                                                (click)="setLanguage('en')">
                                                {{ "HEADER.ENGLISH" | translate }}
                                                <img loading="lazy" [src]="getLanguageFlag('en')" class="mb-1 mx-1"
                                                    alt="" />
                                            </div>
                                            <- {{ "HEADER.LANGUAGE" | translate }} </div>
                                                <div *ngIf="item.lang === 'ar'" class="d-flex justify-content-between">
                                                    {{ "HEADER.LANGUAGE" | translate }}
                                                    <div class="ms-4 lang-bg-light" style="cursor: pointer"
                                                        (click)="setLanguage('ar')">
                                                        العربية
                                                        <img style="width: 16px" loading="lazy"
                                                            [src]="getLanguageFlag('ar')" class="mb-1" alt="" />
                                                    </div>
                                                </div>
                                        </div>

                                        <div class="dropdown-logout pb-3" href="javascript: void(0);" (click)="logout()"
                                            style="cursor: pointer">
                                            {{ "HEADER.LOG_OUT" | translate }}
                                        </div>
                                    </div>

                                    <!--     
                          <a class="dropdown-item" href="javascript: void(0);" (click)="onLayoutChange('/profile')"><i
                              class="bx bx-user-circle font-size-16 align-middle me-1"></i>
                            {{ 'MENUITEMS.PROFILE' | translate}}</a> -->

                                    <!-- <a class="dropdown-item" href="javascript: void(0);" (click)="onLayoutChange('/cart')" ><i
                          class="bx bx-cart font-size-16 align-middle me-1"></i>
                        {{ 'MENUITEMS.CART' | translate}}</a>
                      <a class="dropdown-item" href="javascript: void(0);" (click)="onLayoutChange('/notifications')" ><i
                          class="bx bx-bell font-size-16 align-middle me-1"></i>
                        {{ 'MENUITEMS.NOTIFICATIONS' | translate}}</a> -->
                                    <!-- <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()">
                        {{ 'MENUITEMS.LOGOUT' | translate}}</a> -->
                                </div>
                            </div>
                    </ng-template>
                    <ng-template #supplierTemplate>
                        <div class="ms-4 dropdown d-inline-block mt-2" ngbDropdown>
                            <button type="button" class="btn header-item d-inline-flex align-items-center ps-1"
                                ngbDropdownToggle id="page-header-user-dropdown">
                                <div *ngIf="
                profilePic === undefined ||
                profilePic === null ||
                profilePic === '';
                else profileImg
              " class="header-profile-user avatar-sm mb-1 bg-white">
                                    <span class="avatar-title rounded-circle fs-4 bg-dayim">
                                        {{ userData.fullName[0].toUpperCase() }}
                                    </span>
                                </div>
                                <ng-template #profileImg>
                                    <img loading="lazy" [src]="profilePic" class="header-profile-user mb-1" alt="" />

                                    <!-- <img loading="lazy" class="rounded-circle header-profile-user mb-1"  [src]="profilePic" alt="{{userData.name}}"> -->
                                </ng-template>
                                <span class="d-none d-xl-inline-block mb-1">{{
                                    userData.name
                                    }}</span>
                                <!-- <i class="bx bx-chevron-down d-none d-xl-inline-block mb-1"></i> -->
                            </button>

                            <div class="dropdown-menu mt-2" ngbDropdownMenu
                                [ngClass]="{ 'dropdown-menu-right': cookieValue === 'en' }">
                                <!-- item-->

                                <div class="ps-3 pe-2 pt-1 d-flex" (click)="navigate('/profile')">
                                    <div class="ms-2 mt-2" style="cursor: pointer;">
                                        <ng-container *ngIf="!profilePic; else profileImgPlaceholder">
                                            <span class="avatar-title header-profile-dropdown fs-4 bg-dayim">
                                                {{ userData.fullName[0].toUpperCase() }}
                                            </span>
                                        </ng-container>
                                        <ng-template #profileImgPlaceholder>
                                            <img loading="lazy" [src]="profilePic" class="header-profile-dropdown mb-1"
                                                alt="" />
                                        </ng-template>
                                    </div>
                                    <div class="p-3" style="cursor: pointer;">
                                        <div class="profile-user-label">
                                            {{
                                            userData.fullName.charAt(0).toUpperCase() +
                                            userData.fullName.slice(1)
                                            }}
                                        </div>
                                        <div>{{ userData.email }}</div>
                                    </div>
                                </div>
                                <div class="line-profile"></div>
                                <div style=" max-height: 400px; ">
                                    <div class="dropdown-item" href="javascript: void(0);"
                                        (click)="onLayoutChange('/my-accounts')" style="cursor: pointer">
                                        {{ "HEADER.SETTINGS" | translate }}
                                    </div>

                                    <div class="dropdown-item" href="javascript: void(0);"
                                        (click)="onLayoutChange('/my-rentals')" style="cursor: pointer">
                                        {{ "HEADER.RENTALS" | translate }}
                                    </div>

                                    <div class="dropdown-item" href="javascript: void(0);"
                                        (click)="onLayoutChange('/inquiries')" style="cursor: pointer">
                                        {{ "HEADER.INQUIRIES" | translate }}
                                    </div>

                                    <div class="dropdown-item" href="javascript: void(0);"
                                        (click)="onLayoutChange('/my-contracts')" style="cursor: pointer">
                                        {{ "HEADER.CONTRACTS" | translate }}
                                    </div>

                                    <div class="dropdown-item" href="javascript: void(0);"
                                        (click)="onLayoutChange('/my-deliveries')" style="cursor: pointer">
                                        {{ "HEADER.DELIVERIES" | translate }}
                                    </div>

                                    <div class="dropdown-item" href="javascript: void(0);"
                                        (click)="onLayoutChange('/my-listings')" style="cursor: pointer">
                                        {{ "HEADER.LISTING" | translate }}
                                    </div>
                                    <div class="dropdown-item" href="javascript: void(0);"
                                        (click)="onLayoutChange('/account-deleteing')" style="cursor: pointer">
                                        {{ "HEADER.DELETE_YOUR_ACCOUNT" | translate }}
                                    </div>

                                    <div class="dropdown-item" href="javascript: void(0);" *ngFor="let item of lang">
                                        <div *ngIf="item.lang === 'en'" class="d-flex justify-content-between">
                                            <div class="ms-4 lang-bg-light" style="cursor: pointer"
                                                (click)="setLanguage('en')">
                                                {{ "HEADER.ENGLISH" | translate }}
                                                <img loading="lazy" [src]="getLanguageFlag('en')" class="mb-1 mx-1"
                                                    alt="" />
                                            </div>
                                            <- {{ "HEADER.LANGUAGE" | translate }} </div>
                                                <div *ngIf="item.lang === 'ar'" class="d-flex justify-content-between">
                                                    {{ "HEADER.LANGUAGE" | translate }}
                                                    <div class="ms-4 lang-bg-light" style="cursor: pointer"
                                                        (click)="setLanguage('ar')">
                                                        العربية
                                                        <img style="width: 16px" loading="lazy"
                                                            [src]="getLanguageFlag('ar')" class="mb-1" alt="" />
                                                    </div>
                                                </div>
                                        </div>

                                        <div class="dropdown-logout pb-3" href="javascript: void(0);" (click)="logout()"
                                            style="cursor: pointer">
                                            {{ "HEADER.LOG_OUT" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </ng-template>

                    <ng-template #defaultTemplate> </ng-template>
                    <!-- <div class="my-2 ms-lg-2">
                <button (click)="onLayoutChange()" class="btn but-style w-xs text-black">Change
                </button>
            </div> -->
                </div>
            </div>
</nav>

<!-- login  -->

<nav *ngIf="userData === null" style="position: fixed" class="navbar navbar-expand-lg navigation sticky navi-top-bar"
    (window:scroll)="windowScroll()" id="navbar" [dir]="textStyle">
    <div class="container col-lg-12">
        <a class="navbar-logo" (click)="logonavigate()">
            <!-- <img loading="lazy" src="assets/images/Logo.png" alt="" height="19" class="logo logo-dark"> -->
            <img style="cursor: pointer" loading="lazy" src="/assets/images/svg/rentalshare_logo.svg" alt=""
                class="logo" />
        </a>

        <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleSidebar()"
            style="cursor: pointer">
            <i class="bx bx-menu-alt-left font-size-32"></i>
        </button>

        <div class="side-drawer" [class.open]="isSidebarOpen">
            <div class="side-drawer-header">
                <button class="close-btn" (click)="toggleSidebar()">&#10005;</button>
            </div>
            <ul class="side-drawer-menu">
                <li (click)="navigate('/about-us')">{{ "HEADER.ABOUT_US" | translate }}</li>
                <li (click)="navigate('/contact-us')">{{ "HEADER.CONTACT_US" | translate }}</li>
                <li (click)="navigate('/account/login')">
                    {{ "HEADER.SIGN_IN" | translate }} / {{ "HEADER.REGISTER" | translate }}
                </li>
            </ul>
        </div>

        <div class="collapse navbar-collapse div-nav-tab" id="topnav-menu-content">
            <ul class="navbar-nav ms-auto m-4 me-2" id="topnav-menu">
                <li class="nav-item" *ngIf="userData === null">
                    <div class="ms-auto m-4 me-2 rent-btn" id="topnav-menu" *ngIf="userData === null"
                        (click)="navigate('/add-listing')" style="cursor: pointer">
                        <div>{{ "HEADER.LIST_EQUIPMENT" | translate }}</div>
                    </div>
                </li>
                <ng-container *ngIf="userData === null">
                    <div class="divider mt-3 mx-2"></div>
                </ng-container>
            </ul>

            <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData === null">
                <div class="ms-auto m-4 me-2 free-btn" id="topnav-menu" *ngIf="userData === null"
                    (click)="navigate('/about-us')" style="cursor: pointer">
                    <div>{{ "HEADER.ABOUT_US" | translate }}</div>
                </div>
            </div>

            <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData === null">
                <div class="ms-auto m-4 me-2 free-btn" id="topnav-menu" *ngIf="userData === null"
                    (click)="navigate('/contact-us')" style="cursor: pointer">
                    <div>{{ "HEADER.CONTACT_US" | translate }}</div>
                </div>
            </div>

            <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData === null">
                <div class="ms-auto m-4 me-2 free-btn" id="topnav-menu" *ngIf="userData === null"
                    (click)="navigate('/account/login')" style="cursor: pointer">
                    <div>
                        {{ "HEADER.SIGN_IN" | translate }} /
                        {{ "HEADER.REGISTER" | translate }}
                    </div>
                </div>
            </div>

            <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData !== null">
                <a class="ps-4" routerLink="/contact-us" style="cursor: pointer">
                    <img loading="lazy" src="/assets/images/svg/help.svg" alt="" height="10" width="10" class="logo" />
                </a>
            </div>

            <!-- <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData !== null">
                <a class="ps-4" routerLink="/notifications">
                    <img loading="lazy" src="/assets/images/svg/notify.svg" alt="" height="10" width="10"
                        class="logo" />
                </a>
            </div> -->

            <!-- <div *ngIf=" userData !== null && userData.userType.typeName === 'customer'; then customerTemplate ">
                <a class="ps-4" routerLink="/profile">
                    <img loading="lazy" [src]="userData?.profile" alt="" height="10" width="10"
                        class="logo" />
                </a>
            </div> -->
            <!-- <div *ngIf=" userData !== null && userData.userType.typeName === 'supplier'; then supplierTemplate ">
                <a class="ps-4" routerLink="/profile">
                    <img loading="lazy" [src]="userData?.profile" alt="" height="10" width="10"
                        class="logo" />
                </a>
            </div> -->




            <ng-template #defaultTemplate> </ng-template>
            <!-- <div class="my-2 ms-lg-2">
                <button (click)="onLayoutChange()" class="btn but-style w-xs text-black">Change
                </button>
            </div> -->

        </div>
    </div>
</nav>
<nav *ngIf="userData != null && userData.status === 4" style="position: fixed"
    class="navbar navbar-expand-lg navigation sticky navi-top-bar" (window:scroll)="windowScroll()" id="navbar"
    [dir]="textStyle">
    <div class="container col-lg-12">
        <a class="navbar-logo" (click)="status4logonavigate()">
            <!-- <img loading="lazy" src="assets/images/Logo.png" alt="" height="19" class="logo logo-dark"> -->
            <img style="cursor: pointer" loading="lazy" src="/assets/images/svg/rentalshare_logo.svg" alt=""
                class="logo" />
        </a>
        <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" data-toggle="collapse"
            data-target="#topnav-menu-content" (click)="toggleMenu()" style="cursor: pointer">
            <i class="bx bx-menu-alt-left font-size-32"></i>
        </button>

        <div class="collapse navbar-collapse div-nav-tab" id="topnav-menu-content">
            <ul class="navbar-nav ms-auto m-4 me-2" id="topnav-menu">
                <!-- <li class="nav-item" *ngIf="userData === null">
                    <div class="ms-auto m-4 me-2 rent-btn" id="topnav-menu" *ngIf="userData === null"
                        (click)="navigate('/add-listing')" style="cursor: pointer">
                        <div>{{ "HEADER.LIST_EQUIPMENT" | translate }}</div>
                    </div>
                </li> -->
                <ng-container *ngIf="userData === null">
                    <div class="divider mt-3 mx-2"></div>
                </ng-container>
            </ul>
            <!-- <ul class="navbar-nav ms-auto m-4 me-2" id="topnav-menu"
                *ngIf="userData !== null && userType === 'supplier'">
                <li class="nav-item">
                    <div class="ms-auto m-4 me-2 rent-btn" id="topnav-menu" (click)="listNavigate()"
                        style="cursor: pointer">
                        <div>{{ "HEADER.LIST_EQUIPMENT" | translate }}</div>
                    </div>
                </li>
                <ng-container *ngIf="userData !== null && userType === 'supplier'">
                    <div class="divider mt-3 mx-2"></div>
                </ng-container>
            </ul> -->

            <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData.status === 4">
                <div class="ms-auto m-4 me-2 free-btn" id="topnav-menu" *ngIf="userData.status === 4"
                    (click)="navigate('/about-us')" style="cursor: pointer">
                    <div>{{ "HEADER.ABOUT_US" | translate }}</div>
                </div>
            </div>

            <!-- <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData === null">
                <div class="ms-auto m-4 me-2 free-btn" id="topnav-menu" *ngIf="userData === null"
                    (click)="navigate('/contact-us')" style="cursor: pointer">
                    <div>{{ "HEADER.CONTACT_US" | translate }}</div>
                </div>
            </div> -->

            <!-- <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData === null">
                <div class="ms-auto m-4 me-2 free-btn" id="topnav-menu" *ngIf="userData === null"
                    (click)="navigate('/account/login')" style="cursor: pointer">
                    <div>
                        {{ "HEADER.SIGN_IN" | translate }} /
                        {{ "HEADER.REGISTER" | translate }}
                    </div>
                </div>
            </div> -->

            <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData.status === 4">
                <a class="ps-4" routerLink="/contact-us" style="cursor: pointer">
                    <img loading="lazy" src="/assets/images/svg/help.svg" alt="" height="10" width="10" class="logo" />
                </a>
            </div>

            <!-- <div class="my-2 ms-lg-2 div-sub-nav-tab" *ngIf="userData !== null">
                <a class="ps-4" routerLink="/notifications">
                    <img loading="lazy" src="/assets/images/svg/notify.svg" alt="" height="10" width="10"
                        class="logo" />
                </a>
            </div> -->

            <div *ngIf=" userData !== null && userData.userType.typeName === 'customer'; then customerTemplate ">
                <a class="ps-4" routerLink="/profile">
                    <img loading="lazy" src="/assets/images/profile/user.svg" alt="" height="10" width="10"
                        class="logo" />
                </a>
            </div>
            <div *ngIf=" userData !== null && userData.userType.typeName === 'supplier'; then supplierTemplate ">
                <a class="ps-4" routerLink="/profile">
                    <img loading="lazy" src="/assets/images/profile/user.svg" alt="" height="10" width="10"
                        class="logo" />
                </a>
            </div>

            <ng-template #customerTemplate>
                <div class="ms-4 dropdown d-inline-block mt-2" ngbDropdown>
                    <button type="button" class="btn header-item d-inline-flex align-items-center ps-1"
                        ngbDropdownToggle id="page-header-user-dropdown">
                        <div *ngIf=" userData.profile === undefined || userData?.profile === null || userData?.profile === ''; else profileImg "
                            class="header-profile-user avatar-sm mb-1 bg-white">
                            <span class="avatar-title rounded-circle fs-4 bg-dayim">
                                {{ userData.fullName[0].toUpperCase() }}
                            </span>
                        </div>
                        <ng-template #profileImg>
                            <img loading="lazy" src="/assets/images/profile/user.svg" class="header-profile-user mb-1"
                                alt="" />

                            <!-- <img loading="lazy" class="rounded-circle header-profile-user mb-1"  [src]="userData.profile" alt="{{userData.name}}"> -->
                        </ng-template>
                        <span class="d-none d-xl-inline-block mb-1">{{
                            userData.name
                            }}</span>
                        <!-- <i class="bx bx-chevron-down d-none d-xl-inline-block mb-1"></i> -->
                    </button>

                    <div class="dropdown-menu mt-2" ngbDropdownMenu
                        [ngClass]="{ 'dropdown-menu-right': cookieValue === 'en' }">
                        <!-- item-->

                        <div class="ps-3 pe-2 pt-1 d-flex" (click)="navigate('/profile')">
                            <div class="ms-2 mt-2">
                                <img loading="lazy" src="../../../../assets/images/user.svg"
                                    class="header-profile-dropdown mb-1" alt="" />
                            </div>
                            <div class="p-3">
                                <div class="profile-user-label">
                                    {{
                                    userData.fullName.charAt(0).toUpperCase() +
                                    userData.fullName.slice(1)
                                    }}
                                </div>
                                <div>{{ userData.email }}</div>
                            </div>
                        </div>
                        <div class="line-profile"></div>
                        <div style=" height: 100px;">
                            <div class="dropdown-item" href="javascript: void(0);" *ngFor="let item of lang">
                                <div *ngIf="item.lang === 'en'" class="d-flex justify-content-between">
                                    <div class="ms-4 lang-bg-light" style="cursor: pointer" (click)="setLanguage('en')">
                                        {{ "HEADER.ENGLISH" | translate }}
                                        <img loading="lazy" [src]="getLanguageFlag('en')" class="mb-1 mx-1" alt="" />
                                    </div>
                                    <- {{ "HEADER.LANGUAGE" | translate }} </div>
                                        <div *ngIf="item.lang === 'ar'" class="d-flex justify-content-between">
                                            {{ "HEADER.LANGUAGE" | translate }}
                                            <div class="ms-4 lang-bg-light" style="cursor: pointer"
                                                (click)="setLanguage('ar')">
                                                العربية
                                                <img style="width: 16px" loading="lazy" [src]="getLanguageFlag('ar')"
                                                    class="mb-1" alt="" />
                                            </div>
                                        </div>
                                </div>

                                <div class="dropdown-logout pb-3" href="javascript: void(0);" (click)="logout()"
                                    style="cursor: pointer">
                                    {{ "HEADER.LOG_OUT" | translate }}
                                </div>
                            </div>

                            <!--     
                          <a class="dropdown-item" href="javascript: void(0);" (click)="onLayoutChange('/profile')"><i
                              class="bx bx-user-circle font-size-16 align-middle me-1"></i>
                            {{ 'MENUITEMS.PROFILE' | translate}}</a> -->

                            <!-- <a class="dropdown-item" href="javascript: void(0);" (click)="onLayoutChange('/cart')" ><i
                          class="bx bx-cart font-size-16 align-middle me-1"></i>
                        {{ 'MENUITEMS.CART' | translate}}</a>
                      <a class="dropdown-item" href="javascript: void(0);" (click)="onLayoutChange('/notifications')" ><i
                          class="bx bx-bell font-size-16 align-middle me-1"></i>
                        {{ 'MENUITEMS.NOTIFICATIONS' | translate}}</a> -->
                            <!-- <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()">
                        {{ 'MENUITEMS.LOGOUT' | translate}}</a> -->
                        </div>
                    </div>
            </ng-template>
            <ng-template #supplierTemplate>
                <div class="ms-4 dropdown d-inline-block mt-2" ngbDropdown>
                    <button type="button" class="btn header-item d-inline-flex align-items-center ps-1"
                        ngbDropdownToggle id="page-header-user-dropdown">
                        <div *ngIf="
                userData.profile === undefined ||
                  userData?.profile === null ||
                  userData?.profile === '';
                else profileImg
              " class="header-profile-user avatar-sm mb-1 bg-white">
                            <span class="avatar-title rounded-circle fs-4 bg-dayim">
                                {{ userData.fullName[0].toUpperCase() }}
                            </span>
                        </div>
                        <ng-template #profileImg>
                            <img loading="lazy" src="/assets/images/profile/user.svg" class="header-profile-user mb-1"
                                alt="" />

                            <!-- <img loading="lazy" class="rounded-circle header-profile-user mb-1"  [src]="userData.profile" alt="{{userData.name}}"> -->
                        </ng-template>
                        <span class="d-none d-xl-inline-block mb-1">{{
                            userData.name
                            }}</span>
                        <!-- <i class="bx bx-chevron-down d-none d-xl-inline-block mb-1"></i> -->
                    </button>

                    <div class="dropdown-menu mt-2" ngbDropdownMenu
                        [ngClass]="{ 'dropdown-menu-right': cookieValue === 'en' }">
                        <!-- item-->

                        <div class="ps-3 pe-2 pt-1 d-flex" (click)="navigate('/profile')">
                            <div class="ms-2 mt-2">
                                <img loading="lazy" src="../../../../assets/images/user.svg"
                                    class="header-profile-dropdown mb-1" alt="" />
                            </div>
                            <div class="p-3">
                                <div class="profile-user-label">
                                    {{
                                    userData.fullName.charAt(0).toUpperCase() +
                                    userData.fullName.slice(1)
                                    }}
                                </div>
                                <div>{{ userData.email }}</div>
                            </div>
                        </div>
                        <div class="line-profile"></div>
                        <div style=" max-height: 400px;">

                            <div class="dropdown-item" href="javascript: void(0);" *ngFor="let item of lang">
                                <div *ngIf="item.lang === 'en'" class="d-flex justify-content-between">
                                    <div class="ms-4 lang-bg-light" style="cursor: pointer" (click)="setLanguage('en')">
                                        {{ "HEADER.ENGLISH" | translate }}
                                        <img loading="lazy" [src]="getLanguageFlag('en')" class="mb-1 mx-1" alt="" />
                                    </div>
                                    <- {{ "HEADER.LANGUAGE" | translate }} </div>
                                        <div *ngIf="item.lang === 'ar'" class="d-flex justify-content-between">
                                            {{ "HEADER.LANGUAGE" | translate }}
                                            <div class="ms-4 lang-bg-light" style="cursor: pointer"
                                                (click)="setLanguage('ar')">
                                                العربية
                                                <img style="width: 16px" loading="lazy" [src]="getLanguageFlag('ar')"
                                                    class="mb-1" alt="" />
                                            </div>
                                        </div>
                                </div>

                                <div class="dropdown-logout pb-3" href="javascript: void(0);" (click)="logout()"
                                    style="cursor: pointer">
                                    {{ "HEADER.LOG_OUT" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
            </ng-template>

            <ng-template #defaultTemplate> </ng-template>
            <!-- <div class="my-2 ms-lg-2">
                <button (click)="onLayoutChange()" class="btn but-style w-xs text-black">Change
                </button>
            </div> -->
        </div>
    </div>
</nav>